<template>
    <v-container
        class="py-8 px-6 pl-12"
        fluid
      >
        <v-row>
          <v-col cols="12"><p class="cont-title">도움말</p></v-col>
        </v-row>

      </v-container>
</template>
<script>

export default {
  name: 'svc_user',
  components: {
  },
  data: () => ({
  }),
  created () { 
    this.$store.commit('layoutFlag', true)
    this.$store.commit('menuId', 'help')
  }
}
</script>

